/** @jsxImportSource react */
import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import ButtonCTA from './ButtonCTA'
import FontAwesomeIcon from './FontAwesomeIcon'
import ProductSmall from './ProductSmall'

export default function ShopNavigationCategories(props: any) {
  const [selected, setSelected] = useState(0)
  const categoriesRef = useRef<HTMLDivElement>(null)
  const concernsRef = useRef<HTMLUListElement[] | null>(null)
  const { featuredProducts } = props.categories[selected].rootItem

  const change = (key: number) => setSelected(key)

  useEffect(() => {
    concernsRef.current = [
      ...(categoriesRef.current?.querySelectorAll(`.concerns [data-concern-key]`) as NodeList),
    ] as HTMLUListElement[]
  }, [])

  useEffect(() => {
    concernsRef.current?.forEach((concernEl) => {
      if (concernEl.dataset.concernKey === selected.toString()) {
        concernEl.dataset.selected = 'true'
        concernEl.focus()
      } else {
        delete concernEl.dataset.selected
      }
    })
  }, [selected])

  return (
    <div ref={categoriesRef} className="categories-grid">
      <div className="l-stack" data-grid="categories">
        <div className="l-stack l-stack--sm u-max-w-[280px]">
          <ul role="list" className="l-stack">
            {props.categories.map(({ rootItem }, i: number) => (
              <li
                className={clsx('action u-flex u-w-full u-items-center u-pr-4', selected === i && 'u-bg-light')}
                key={rootItem.label}
                onMouseEnter={() => change(i)}
              >
                <a
                  href={rootItem.url}
                  className="u-flex u-flex-1 u-items-center u-justify-between u-py-2 u-pl-3 u-text-black"
                >
                  <span dangerouslySetInnerHTML={{ __html: rootItem.label }} />
                </a>
                <button onClick={() => change(i)} className="u-ml-2 u-flex u-items-center">
                  <FontAwesomeIcon icon="chevron-right" size="md" />
                </button>
              </li>
            ))}
          </ul>
          <ButtonCTA display="block" className="desktop:u-text-[1rem]" />
        </div>
      </div>
      <div data-grid="concerns">
        <div className="u-max-w-[280px]">
          <div className="concerns">{props.children}</div>
        </div>
      </div>
      <div data-grid="featured">
        <div className="l-stack">
          <b className="p4">Most Popular</b>
          <div>
            <ul role="list" className="u-flex u-space-x-[30px]">
              {featuredProducts.map((product, i) => (
                <li key={selected + '_' + i} className="u-w-1/3">
                  <ProductSmall product={product} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
